<template>
  <v-container fluid class="mt-8">
        <v-row>
            <v-col cols="6">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="searchByName"
                    :label="$t('teacher.search_name')"
                    dense
                    clearable
                />
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn dark @click="create">
                    {{$t('teacher.create')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
    mobile-breakpoint=0
            class="elevation-4"
            :headers="i18nHeader"
            :loading="loading"
            :items="teacherList"
            :search="searchByName"
            :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ (item.nameZh)? item.nameZh : '-' }} </td>
                    <td>{{ (item.nameEng)? item.nameEng : '-' }} </td>
                    <td>{{ (permissions == 'admin')? handledMerchantID(item.merchantId) : stateMerchantName }} </td>
                    <td> {{$moment(item.createdAt).format('YYYY/MM/DD HH:mm')}}</td>
                    <!-- <td>
                        <v-btn small :color="(item.published)? 'success': 'error'" @click="changeStatus(item, index)"> <v-icon left>mdi-{{(item.published)? 'check': 'close'}}</v-icon> {{showStatus(item.published)}}</v-btn>
                    </td> -->
                    <td>
                        <v-icon @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </td>
                </tr>
                </template>
        </v-data-table>
        <FormDialog ref="dialog" @refresh="getAllTeacher" :merchantsList="merchantsList"/>
        <confirmDialog ref="confirmDialog"/>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FormDialog from "@/views/Teacher/TeacherDialog"
import confirmDialog from "@/components/ConfirmDialog"

import api from '@/api'

export default {
    components:{
        FormDialog,
        confirmDialog
    },
    mounted(){
        this.getAllTeacher()
        this.getAllMerchant()
    },
    computed:{
        ...mapState(['lang', 'permissions', 'stateMerchantName']),
        i18nHeader(){
            let result = this.headers

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        }
    },
    watch: {},
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllTeacher() {
            this.loading = true
            try{
                let result = await api.get('/teacher')

                if(result){
                    this.teacherList = result.data

                    if(this.$route.query && this.$route.query.id)
                    {
                        let tmpArr = []

                        this.teacherList.map(item => {
                            if(item.merchantId == this.$route.query.id){
                                tmpArr.push(item)
                            }
                        })

                        this.teacherList = tmpArr
                        this.$route.query.id = ''
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllMerchant() {
            let apiTarget = (this.permissions == 'admin')? '/merchants/all' : '/merchants/published'

            try {
                let result = await api.get(apiTarget)

                if(result){
                    this.merchantsList = (this.permissions == 'admin')? result.data : result.data.docs
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        handledMerchantID(id){
            let result = id

            if(id && this.merchantsList.length > 0){
                for (const [key, value] of Object.entries(this.merchantsList)) {
                    if(value['_id'] == id) result = this.merchantsList[key]['name']
                }
            }

            return result
        },
        create(){
            this.$refs.dialog.open('create', '', 'teacher')
        },
        edit(item){
            this.$refs.dialog.open('edit', item, 'teacher')
        }
    },
    data: () => ({
        headers: [
            {i18n: 'common.name_zh',value: 'nameZh'},
            {i18n: 'common.name_eng',value: 'nameEng'},
            {i18n: 'merchant.name',value: 'userId'},
            {i18n: 'common.created_at', value: 'createdAt'},
            {i18n: 'common.edit', value: '', sortable: false },
        ],
        searchByName: '',
        teacherList:[],
        merchantsList: [],
        loading: false
    })
}
</script>