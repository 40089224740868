<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('teacher.'+action) }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid"
                            lazy-validation
                        >
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="teacherDetailObj.nameZh"
                                        :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('teacher.name_zh')"
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="teacherDetailObj.nameEng"
                                        :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('teacher.name_eng')"
                                    />
                                </v-col>
                                <v-col cols="4" v-if="permissions == 'admin'">
                                    <v-combobox
                                        v-model="teacherDetailObj.merchantId"
                                        :items="merchantsList"
                                        item-value="_id"
                                        item-text="name"
                                        :label="$t('merchant.name')"
                                        :rules="[v => !!v || $t('common.required')]"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" class="py-0" >
									<v-textarea
										v-model="teacherDetailObj.descriptionZh"
										:label="$t('common.description_zh')"
										rows="2"
                                        :rules="[v => !!v || $t('common.required')]"
									/>
								</v-col>
								<v-col cols="12" class="py-0">
									<v-textarea
										v-model="teacherDetailObj.descriptionEng"
										:label="$t('common.description_eng')"
										rows="2"
                                        :rules="[v => !!v || $t('common.required')]"
									/>
								</v-col>
                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('teacher.image') }}</v-card-title>
                                        <v-card-text>
                                            <v-img
                                                v-if="teacherDetailObj.image && teacherDetailObj.image.large && teacherDetailObj.image.large.path"
                                                class="mx-auto"
                                                :src="teacherDetailObj.image.large.path"
                                                max-width="500"
                                            />
                                            <file-pond
                                                v-else
                                                name="upload"
                                                ref="pond"
                                                :label-idle="$t('img_label')"
                                                accepted-file-types="image/jpeg, image/png"
                                                :files="uploadImageFile"
                                                max-file-size="5MB"
                                                v-on:removefile="teacherDetailObj.image = ''"
                                            />
                                        </v-card-text>
                                        <v-card-actions v-if="teacherDetailObj.image && teacherDetailObj.image.large && teacherDetailObj.image.large.path">
                                            <v-spacer/>
                                                <v-btn @click="teacherDetailObj.image = ''" text>
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-text-field
                                        :value="$moment(teacherDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        disabled
                                        :label="$t('common.created_at')"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{$t('common.save')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    components:{
        FilePond
    },
    mounted() {
		let self = this
		this.setOptions({
			server: {
				process: async function ( fieldName, files, metadata, load, error, progress, abort) {
					try {
						let formData = new FormData()
						formData.append('file', files)

						let result = await api.post('/image', formData, {
							headers: {'Content-Type': 'multipart/form-data'}
						})

						if (result) {
                            self.teacherDetailObj.image = result.data._id
						}
						load(true)
					} catch (err) {
						console.log(err.message);
						abort(true)
					}
				}
			},
			imageResizeMode: 'contain',
		})
	},
    computed:{
        ...mapState(['lang', 'permissions', 'stateMerchantId', 'stateMerchantName'])
    },
    props:['merchantsList'],
    methods:{
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item){
            if(action == 'edit'){
                let result = false

                try {
                    result = await api.get('/teacher/'+ item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if(result) this.teacherDetailObj = result.data
            }

            if(this.permissions != 'admin') this.teacherDetailObj.merchantId = this.stateMerchantId

            this.action = action
            this.dialog = true
        },
        async save(){
            let validated = this.$refs.form.validate()

            if(validated){
                let result = false
                this.loading = true

                if(this.teacherDetailObj.image == ''){
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_upload_img'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                if(this.permissions == 'admin') this.teacherDetailObj.merchantId = this.teacherDetailObj.merchantId._id

                try {
                    if(this.action == 'create'){
                        result = await api.post('/teacher', this.teacherDetailObj)
                    }else{
                        result = await api.patch('/teacher', this.teacherDetailObj)
                    }

                    if(result) {
                        let targetI18n = (this.action === 'edit')? 'global_alert.success_edit' : 'global_alert.success_create'
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t( targetI18n ),
                            icon   : 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                this.loading = false
            }
        },
        close(){
            this.resetForm()
            this.dialog = false
        },
        resetForm() {
            this.$emit('refresh')
            this.uploadImageFile = []
            this.teacherDetailObj = {
                merchantId: '',
                nameZh: '',
                nameEng: '',
                descriptionZh: '',
                descriptionEng: '',
                image: ''
            }
        },
    },
    data: () => ({
        setOptions,
        dialog: false,
        uploadImageFile: [],
        teacherDetailObj:{
            merchantId: '',
            nameZh: '',
            nameEng: '',
            descriptionZh: '',
            descriptionEng: '',
            image: ''
        },
        action: 'edit',
        formValid: true,
        openMenu: false,
        closeMenu: false,

    })
}
</script>